<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title>Home Page Sliders</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="addHomeSliderModal = true"
              class="mx-2"
              fab
              dark
              small
              color="#1976d2"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :search="search"
              :items="allHomePageSlider"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300, -1]
              }"
              :items-per-page="50"
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search (Home Page Sliders Details)"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item.event_id ? row.item.event_id.name : '' }}</td>
                 <td>
                    <v-chip x-small label :color="getColor(row.item.isActive)" dark>
                     {{ row.item.isActive }}
                    </v-chip>
                  </td>
                  <td>
                    <a :href="imageBase + row.item.slider" target="_blank" style="text-decoration: none;"><v-icon color="primary">mdi-eye</v-icon> View</a>
                  </td>
                  <td>{{ dateFormat(row.item.created_date) }}</td>
                  <td><a @click="showDeleteDilog(row.item)"><v-icon color="red">mdi-delete</v-icon></a></td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
    <create-home-page-slider :show-model="addHomeSliderModal" @close="closeHomepageModal"></create-home-page-slider>
    <delete-dialog :contentText="deleteText" :showModel="showDeleteModel" :id="deleteId" :headerText="'Delete Home Page Slider'" @action="deleteAction"></delete-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import Snackbar from '../../Snackbar.vue';
import CreateHomePageSlider from './CreateHomePageSlider.vue';
import DeleteDialog from '../../DeleteDialog.vue';

export default {
  name: "HomePageSliders",
  components: {
    Snackbar,
    CreateHomePageSlider,
    DeleteDialog
  },
  data: () => ({
    search: "",
    addHomeSliderModal: false,
    allHomePageSlider:[],
    imageBase:'',
    tableLoading:false,
    showDeleteModel:false,
    deleteId:'',
    deleteText:'',
    headers: [
      { text: "Event", value: "event_id.name" },
      { text: "Is Active", value: "isActive" },
      { text: "Slider Url", value: "slider" },
      { text: "Created at", value: "created_date" },
      { text: "Action" },
    ],
  }),
  watch:{
    
  },
  computed: {},
  mounted: function () { 
    this.getHomePageSlider();
  },
  created: function () {},
  methods: {
    getColor(isActive) {
      if (isActive) return "green";
      else return "orange";
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    closeHomepageModal(){
      this.getHomePageSlider();
      this.addHomeSliderModal = false;
    },

    getHomePageSlider(){
      this.tableLoading = true;
      axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/home-page-slider")
        .then((res) => {
          const response = res.data;
          this.allHomePageSlider = response.data.allHomePageSlider;
          this.imageBase = response.data.imageBase;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    showDeleteDilog(homePageSlider){
      this.deleteText = 'Are you sure, You want to delete this <b>'+ 'Home page Slider' +'</b>.';
      this.deleteId = homePageSlider._id;
      this.showDeleteModel = true;
    },
    deleteAction(actionType,id){
      if(actionType == 'delete'){
        axios.delete(this.Node_JS_HOST + "/api/v1/web/banner-master/home-page-slider/"+id)
        .then((res) => {
          if(res){
            this.getHomePageSlider();
            this.snackbarText = 'Home Page Slider Deleted';
            this.isShowSnackbar = true;
          }
        })
        .catch((error) => {
          this.errorHandle(error);
          this.snackbarText = 'Something went wrong please try again';
          this.isShowSnackbar = true;
        });
      }
      this.showDeleteModel = false;
      this.deleteId = '';
    },
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
  .w-100{
    max-width: 100px;
  }
  .w-unset{
    max-width: unset;
  }
</style>