<template>
  <div class="text-center">
    <v-dialog v-model="showModel" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Home Page Sliders</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="HomePageSliderForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :rules="[v => !!v || 'Events is required']"
                    label="Events *"
                    item-text="name"
                    item-value="_id"
                    v-model="addHomeSliderFrom.event_id"
                    :items="allEvent"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    label="Slider *"
                    dense
                    :rules="[v => !!v || 'Slider is required',v => (v && v.size > 0) || 'Slider is required']"
                    v-model="addHomeSliderFrom.slider"
                    prepend-icon="mdi-camera"
                    @change="previewImage('slider')"
                  ></v-file-input>
                  <v-img
                    v-if="previewSliderImage"
                    :lazy-src="previewSliderImage"
                    max-height="250"
                    max-width="700"
                    :src="previewSliderImage"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                 <v-switch
                  v-model="addHomeSliderFrom.isActive"
                  label="Is Active"
                ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetAddHomeSliderModal()">
            Close
          </v-btn>
          <v-btn color="primary" @click="saveHomeSlider()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :text="snackbarText"
      :showSnackbar="isShowSnackbar"
      @hide="isShowSnackbar = false"
    ></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "../../Snackbar.vue";
export default {
  name: "CreateHomePageSlider",
  components: {
    Snackbar,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    addHomeSliderFrom:{
      isActive:true,
      slider: [],
      event_id: '',
    },
    allEvent:[],
    valid: true,
    previewSliderImage: '',
  }),
  watch: { 
    showModel(val){
      if(val){
        this.getAllEventList(); 
      }
    } 
  },
  computed: {},
  mounted: function () {
  },
  created: function () {},
  methods: {
    previewImage: function(fileInput) {
      var reader = new FileReader();
      if(fileInput === 'slider'){
        if(this.addHomeSliderFrom.slider){
          reader.onload = (e) => {
            this.previewSliderImage = e.target.result;
          }
          reader.readAsDataURL(this.addHomeSliderFrom.slider);
        }else{
          this.previewSliderImage = '';
        }
      }
    },
    saveHomeSlider: function(){
      if (this.$refs.HomePageSliderForm.validate()) {
        let formData = new FormData();
        formData.append('isActive', this.addHomeSliderFrom.isActive);
        formData.append('slider', this.addHomeSliderFrom.slider);
        formData.append('event_id', this.addHomeSliderFrom.event_id);
        axios.post(this.Node_JS_HOST + "/api/v1/web/banner-master/home-page-slider",formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res) {
              this.resetAddHomeSliderModal();
              this.snackbarText = "Home Page Slider add Successfully";
              this.isShowSnackbar = true;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
    getAllEventList(){
      axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/event")
      .then((res) => {
          const response = res.data;
          this.allEvent = response.data.allEvent;
      })
    },
    resetAddHomeSliderModal() {
      this.addHomeSliderFrom = {
        isActive:true,
        event_id: '',
        slider: [],
      };
      this.previewSliderImage = '';
      this.$emit('close');
    },
  },
};
</script>